import React from 'react';
import Helmet from 'react-helmet';

import Form from '@nib-components/form';
import Section from '@nib-components/section';
import { FormPanel } from '@nib/content-services-library/dist/cjs/components/form-panel';

import Layout from '../components/Layout';
import metrics from '../metrics';
import config from '../utils/env';

const title = 'nib Programme Enquiry Form';
const metaDescription =
  'To support your journey to better health, nib offers eligible members access to Health Management Programmes at no extra cost.';

const ProgrammeEnquiryFormPage = () => (
  <Layout>
    <Helmet>
      <title>{title} | nib</title>
      <meta name="description" content={metaDescription} />
    </Helmet>
    <Section>
      <Form id="programme-enquiry-form" name="programmeEnquiryForm">
        <div id="flowContainer" className="sf-forms" />
        <FormPanel
          variant="salesforceFlow"
          scriptSrc={config.programmeEnquiryFormScriptSource}
          appName="c:flowApp"
          componentName="lightning:flow"
          domLocator="flowContainer"
          flowName="HealthCare_Programme_Registration_Flow"
          attributes={{ class: 'myCustomClass' }}
        />
      </Form>
    </Section>
  </Layout>
);

export default metrics({ pageName: 'programme-enquiry-form' })(ProgrammeEnquiryFormPage);
